import { useCallback } from 'react'
import { ChainId, Currency, Token } from '@abstra-dex/sdk'
import styled from 'styled-components'
import {
  Button,
  Text,
  ErrorIcon,
  ArrowUpIcon,
  MetamaskIcon,
  Flex,
  Box,
  Link,
  Spinner,
  Modal,
  InjectedModalProps,
  ModalHeader,
} from 'packages/uikit'
import { registerToken } from 'utils/wallet'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { WrappedTokenInfo } from 'state/types'
import { AbstradexButton } from 'components/abstradex/components/ui/Button'
import { RowFixed } from '../Layout/Row'
import { AutoColumn, ColumnCenter } from '../Layout/Column'
import { getBscScanLink } from '../../utils'

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 24px 0;
`

const ConfirmTextColor = styled(Text)``

function ConfirmationPendingContent({ pendingText }: { pendingText: string }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <ConfirmedIcon>
        {/* <Spinner /> */}
        <img src="/images/abstradex/loading_abs.gif" alt="animation" />
      </ConfirmedIcon>
      <AutoColumn gap="12px" justify="center">
        <ConfirmTextColor color="absBlackColor" fontSize="14px">
          {t('Waiting For Confirmation')}
        </ConfirmTextColor>
        <AutoColumn gap="12px" justify="center">
          <ConfirmTextColor color="absBlackColor" bold small textAlign="center">
            {pendingText}
          </ConfirmTextColor>
        </AutoColumn>
        <ConfirmTextColor color="absBlackColor" fontSize="14px" textAlign="center" fontStyle="italic">
          {t('Confirm this transaction in your wallet')}
        </ConfirmTextColor>
      </AutoColumn>
    </Wrapper>
  )
}

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  const { library } = useActiveWeb3React()

  const { t } = useTranslation()

  const token: Token | undefined = wrappedCurrency(currencyToAdd, chainId)

  return (
    <Wrapper>
      <Section>
        {/* <ConfirmedIcon>
          <ArrowUpIcon strokeWidth={0.5} width="90px" color="primary" />
        </ConfirmedIcon> */}
        <AutoColumn gap="12px" justify="center">
          <ConfirmTextColor color="absBlackColor" bold fontSize="14px">
            {t('Transaction Submitted')}
          </ConfirmTextColor>
          {chainId && hash && (
            <Link
              external
              fontSize="14px"
              style={{ fontStyle: 'italic' }}
              color="absBlackColor"
              href={getBscScanLink(hash, 'transaction', chainId)}
            >
              {t('View on Zeta Explorer')}
            </Link>
          )}
          {currencyToAdd && library?.provider?.isMetaMask && (
            <AbstradexButton.AbstradexStyledActionButton
              variant="tertiary"
              mt="12px"
              width="fit-content"
              onClick={() =>
                registerToken(
                  token.address,
                  token.symbol,
                  token.decimals,
                  token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                )
              }
              style={{
                backgroundColor: '#323436',
                color: '#ffffff',
                fontWeight: '500',
              }}
            >
              <RowFixed>
                {t('Add %asset% to Metamask', { asset: currencyToAdd.symbol })}
                <MetamaskIcon width="22px" ml="6px" />
              </RowFixed>
            </AbstradexButton.AbstradexStyledActionButton>
          )}
          <AbstradexButton.AbstradexStyledActionButton width="fit-content" onClick={onDismiss} mt="20px">
            {t('Close')}
          </AbstradexButton.AbstradexStyledActionButton>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Box>{topContent()}</Box>
      <Box>{bottomContent()}</Box>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AutoColumn justify="center" gap="md">
        <ErrorIcon color="absMainColor" width="64px" />
        <ConfirmTextColor
          color="failure"
          style={{ textAlign: 'center', width: '85%', wordBreak: 'break-word', marginBottom: '16px' }}
        >
          {message}
        </ConfirmTextColor>
      </AutoColumn>

      <Flex justifyContent="center" pt="24px">
        <AbstradexButton.AbstradexStyledActionButton onClick={onDismiss}>
          {t('Dismiss')}
        </AbstradexButton.AbstradexStyledActionButton>
      </Flex>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
}

const TransactionConfirmationModal: React.FC<InjectedModalProps & ConfirmationModalProps> = ({
  title,
  onDismiss,
  customOnDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd,
}) => {
  const { chainId } = useActiveWeb3React()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss?.()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  const StyledModal = styled(Modal)`
    /* height: 68%; */
    > ${ModalHeader} {
      background: unset !important;
    }
  `

  return (
    <StyledModal textScale="lg" title={title} headerBackground="gradients.cardHeader" onDismiss={handleDismiss}>
      {attemptingTxn ? (
        <ConfirmationPendingContent pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={handleDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </StyledModal>
  )
}

export default TransactionConfirmationModal
